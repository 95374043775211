import { useEffect, useState } from "react";
import { NewsArticle } from "./Home";
import axios from "axios";
import { useLocation } from "react-router-dom";

function PopularNews() {
    const [newsData, setNewsData] = useState<NewsArticle[]>([]);
    const location = useLocation();

    useEffect(() => {
        if (window.gtag) {
            window.gtag('config', 'GTM-NGBQ98PB', {
                page_path: location.pathname,
            });
        }
    }, [location]);

    useEffect(() => {
        const fetchNewsData = async () => {
            try {
                const res = await axios.get('https://manage.sportski-info.mk/api/news');
                const news = res.data.data || [];

                const newsWithCommentCount = await Promise.all(
                    news.map(async (newsItem: NewsArticle) => {
                        try {
                            const commentsRes = await axios.get(`https://manage.sportski-info.mk/api/comments/${newsItem.id}`);
                            const comments = commentsRes.data || [];
                            return {
                                ...newsItem,
                                comment_count: comments.length,
                            };
                        } catch (commentError) {
                            console.error(`Грешка при прикажување на коментарот: ${newsItem.id}`, commentError);
                            return {
                                ...newsItem,
                                comment_count: 0,
                            };
                        }
                    })
                );

                const filteredNews = newsWithCommentCount.filter(news => news.comment_count > 1);
                const sortedNews = filteredNews.sort((a, b) => b.comment_count - a.comment_count);
                const top3News = sortedNews.slice(0, 3);

                setNewsData(top3News);
            } catch (error) {
                console.error("Error fetching news data", error);
            }
        };

        fetchNewsData();
    }, []);

    const getImageSource = (imagePath?: string) => {
        if (!imagePath) {
            return `https://manage.sportski-info.mk/uploads/images/placeholder.jpg`;
        }

        const isUrl = (path: string) => /^(http|https):\/\//i.test(path);
        return isUrl(imagePath)
            ? imagePath
            : `https://manage.sportski-info.mk/uploads/images/${imagePath}`;
    };

    if (newsData.length === 0) return null;

    return (
        <div className="w-full my-10 bg-gray-50">
            <div className="text-center mb-6">
                <h2 className="lg:text-3xl text-2xl font-extrabold text-gray-800 mb-2">
                    Популарни Вести
                </h2>
                <div className="w-16 h-1 mx-auto bg-red-600 rounded-full"></div>
            </div>
            <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
                {newsData.map((newsItem) => (
                    <div key={newsItem.id} className="news-item relative group bg-white shadow-lg rounded-lg overflow-hidden hover:scale-105 transition-all duration-300">
                        <a href={`/news/${newsItem.id}`} className="block w-full h-full relative">
                            <img
                                src={getImageSource(newsItem.image_path)}
                                alt={newsItem.title}
                                className="w-full h-48 object-cover group-hover:opacity-80 transition-opacity duration-300"
                            />
                            <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black via-transparent to-transparent py-4 px-6">
                                <h3 className="text-white text-xl font-semibold group-hover:text-red-600 transition-colors duration-300">{newsItem.title}</h3>
                                <div className="text-gray-300 text-sm mt-2">
                                    <span className="flex items-center">
                                        <i className="fas fa-comment-alt mr-2 text-red-600"></i>
                                        {newsItem.comment_count} Коментари
                                    </span>
                                </div>
                            </div>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PopularNews;
