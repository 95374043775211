


// import { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

// export interface SportsName {
//     id: number;
//     name: string;
// }

// export interface Category {
//     id: number;
//     name: string;
//     sport_id: number;
// }

// function Header() {
//     const [isMenuOpen, setIsMenuOpen] = useState(false);
//     const [sports, setSports] = useState<SportsName[]>([]);
//     const [categories, setCategories] = useState<Category[]>([]);
//     const [expandedSportId, setExpandedSportId] = useState<number | null>(null);
//     const navigate = useNavigate();

//     const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

//     const fetchSports = async () => {
//         try {
//             const response = await fetch('https://manage.sportski-info.mk/api/sports');
//             const data = await response.json();
//             if (data.success && Array.isArray(data.data)) {
//                 setSports(data.data);
//             }
//         } catch (error) {
//             console.error('Грешка при прикажување на спортови:', error);
//         }
//     };

//     const fetchCategories = async (sportId: number) => {
//         try {
//             const response = await fetch(`https://manage.sportski-info.mk/api/sports/${sportId}/categories`);
//             const data = await response.json();
//             setCategories(data);
//         } catch (error) {
//             console.error('Грешка при прикажување на категории:', error);
//         }
//     };

//     useEffect(() => {
//         fetchSports();
//     }, []);

//     const handleSportClick = (sportId: number) => {
//         navigate(`/sportsPage?sportId=${sportId}`);
//     };

//     const handleCategoryClick = (categoryId: number, sportId: number) => {
//         navigate(`/sportsPage?sportId=${sportId}&categoryId=${categoryId}`);
//     };

//     const toggleSportDropdown = (sportId: number) => {
//         setExpandedSportId((prev) => (prev === sportId ? null : sportId));
//         if (expandedSportId !== sportId) {
//             fetchCategories(sportId);
//         }
//     };

//     return (
//         <>
//             <header className="bg-gradient-to-r from-black via-gray-900 to-black text-white py-4 shadow-md">
//                 <div className="lg:w-4/5 max-w-screen-xl mx-auto flex justify-between items-center px-5">
//                     <Link to="/" className="logo cursor-pointer">
//                         <img src="/images/logo.png" alt="logo" className="h-10 w-auto" />
//                     </Link>
//                     <button
//                         className="lg:hidden block z-50 fixed right-7 top-5 text-white text-4xl"
//                         onClick={toggleMenu}
//                         aria-label="Toggle Menu"
//                     >
//                         {isMenuOpen ? (
//                             <span className="text-4xl">&times;</span>
//                         ) : (
//                             <img src="/images/hamburger-menu-icon.png" width={32} alt="Menu" />
//                         )}
//                     </button>
//                 </div>
//             </header>

//             <nav className="hidden lg:block w-full bg-gray-800 text-white py-3 shadow">
//                 <ul className="flex gap-8 justify-center font-semibold uppercase">
//                     {sports.map((sport) => (
//                         <li key={sport.id} className="relative">
//                             <div className="flex items-center space-x-2">
//                                 <span
//                                     onClick={() => handleSportClick(sport.id)}
//                                     className="text-lg hover:text-orange-500 cursor-pointer"
//                                 >
//                                     {sport.name}
//                                 </span>
//                                 <button
//                                     onClick={() => toggleSportDropdown(sport.id)}
//                                     className="text-lg"
//                                     aria-label="Toggle Dropdown"
//                                 >
//                                     {expandedSportId === sport.id ? <FiChevronUp /> : <FiChevronDown />}
//                                 </button>
//                             </div>
//                             {expandedSportId === sport.id && categories.length > 0 && (
//                                 <ul className="absolute left-0 mt-2 w-48 bg-gray-700 rounded-lg shadow-md z-50">
//                                     {categories.map((category) => (
//                                         <li
//                                             key={category.id}
//                                             onClick={() => handleCategoryClick(category.id, sport.id)}
//                                             className="p-2 hover:bg-gray-600 rounded cursor-pointer transition-all duration-200"
//                                         >
//                                             {category.name}
//                                         </li>
//                                     ))}
//                                 </ul>
//                             )}
//                         </li>
//                     ))}
//                 </ul>
//             </nav>

//             <div
//                 className={`fixed inset-0 z-40 bg-black bg-opacity-90 transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'
//                     } transition-transform duration-500`}
//             >
//                 <div className="flex flex-col items-start mt-10 px-6 space-y-6">
//                     <ul className="text-white text-2xl space-y-4 w-full mt-8">
//                         {sports.map((sport) => (
//                             <li key={sport.id} className="relative">
//                                 <div className="flex justify-between items-center">
//                                     <span
//                                         onClick={() => handleSportClick(sport.id)}
//                                         className="text-xl uppercase cursor-pointer"
//                                     >
//                                         {sport.name}
//                                     </span>
//                                     <button
//                                         onClick={() => toggleSportDropdown(sport.id)}
//                                         aria-label="Toggle Dropdown"
//                                     >
//                                         {expandedSportId === sport.id ? <FiChevronUp /> : <FiChevronDown />}
//                                     </button>
//                                 </div>
//                                 {expandedSportId === sport.id && categories.length > 0 && (
//                                     <ul className="pl-4 mt-2 bg-gray-800 rounded shadow-lg z-50">
//                                         {categories.map((category) => (
//                                             <li
//                                                 key={category.id}
//                                                 onClick={() => handleCategoryClick(category.id, sport.id)}
//                                                 className="text-lg text-gray-300 hover:text-orange-500 cursor-pointer p-2 rounded"
//                                             >
//                                                 {category.name}
//                                             </li>
//                                         ))}
//                                     </ul>
//                                 )}
//                             </li>
//                         ))}
//                     </ul>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default Header;
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

export interface SportsName {
    id: number;
    name: string;
}

export interface Category {
    id: number;
    name: string;
    sport_id: number;
}

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [sports, setSports] = useState<SportsName[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [expandedSportId, setExpandedSportId] = useState<number | null>(null);
    const navigate = useNavigate();

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const fetchSports = async () => {
        try {
            const response = await fetch('https://manage.sportski-info.mk/api/sports');
            const data = await response.json();
            if (data.success && Array.isArray(data.data)) {
                setSports(data.data);
            }
        } catch (error) {
            console.error('Грешка при прикажување на спортови:', error);
        }
    };

    const fetchCategories = async (sportId: number) => {
        try {
            const response = await fetch(`https://manage.sportski-info.mk/api/sports/${sportId}/categories`);
            const data = await response.json();
            setCategories(data);
        } catch (error) {
            console.error('Грешка при прикажување на категории:', error);
        }
    };

    useEffect(() => {
        fetchSports();
    }, []);

    const handleSportClick = (sportId: number) => {
        navigate(`/sportsPage?sportId=${sportId}`);
        setIsMenuOpen(false); // Close menu after navigation
    };

    const handleCategoryClick = (categoryId: number, sportId: number) => {
        navigate(`/sportsPage?sportId=${sportId}&categoryId=${categoryId}`);
        setIsMenuOpen(false); // Close menu after navigation
    };

    const toggleSportDropdown = (sportId: number) => {
        setExpandedSportId((prev) => (prev === sportId ? null : sportId));
        if (expandedSportId !== sportId) {
            fetchCategories(sportId);
        }
    };

    return (
        <>
            <header className="bg-gradient-to-r from-black via-gray-900 to-black text-white py-4 shadow-md">
                <div className="lg:w-4/5 max-w-screen-xl mx-auto flex justify-between items-center px-5">
                    <Link to="/" className="logo cursor-pointer">
                        <img src="/images/logo.png" alt="logo" className="h-10 w-auto" />
                    </Link>
                    <button
                        className="lg:hidden block z-50 fixed right-7 top-5 text-white text-4xl"
                        onClick={toggleMenu}
                        aria-label="Toggle Menu"
                    >
                        {isMenuOpen ? (
                            <span className="text-4xl">&times;</span>
                        ) : (
                            <img src="/images/hamburger-menu-icon.png" width={32} alt="Menu" />
                        )}
                    </button>
                </div>
            </header>

            <nav className="hidden lg:block w-full bg-gray-800 text-white py-3 shadow">
                <ul className="flex gap-8 justify-center font-semibold uppercase">
                    {sports.map((sport) => (
                        <li key={sport.id} className="relative">
                            <div className="flex items-center space-x-2">
                                <span
                                    onClick={() => handleSportClick(sport.id)}
                                    className="text-lg hover:text-orange-500 cursor-pointer"
                                >
                                    {sport.name}
                                </span>
                                <button
                                    onClick={() => toggleSportDropdown(sport.id)}
                                    className="text-lg"
                                    aria-label="Toggle Dropdown"
                                >
                                    {expandedSportId === sport.id ? <FiChevronUp /> : <FiChevronDown />}
                                </button>
                            </div>
                            {expandedSportId === sport.id && categories.length > 0 && (
                                <ul className="absolute left-0 mt-2 w-48 bg-gray-700 rounded-lg shadow-md z-50">
                                    {categories.map((category) => (
                                        <li
                                            key={category.id}
                                            onClick={() => handleCategoryClick(category.id, sport.id)}
                                            className="p-2 hover:bg-gray-600 rounded cursor-pointer transition-all duration-200"
                                        >
                                            {category.name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </nav>

            <div
                className={`fixed inset-0 z-40 bg-black bg-opacity-90 transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'
                    } transition-transform duration-500`}
            >
                <div className="flex flex-col items-start mt-10 px-6 space-y-6 w-full">
                    {/* Logo in the mobile menu */}
                    <div className="w-full flex justify-center mb-6">
                        <img src="/images/logo.png" alt="logo" className="h-12 w-auto" />
                    </div>
                    <ul className="text-white text-2xl space-y-4 w-full">
                        {sports.map((sport) => (
                            <li key={sport.id} className="relative">
                                <div className="flex justify-between items-center">
                                    <span
                                        onClick={() => handleSportClick(sport.id)}
                                        className="text-xl uppercase cursor-pointer hover:text-orange-500"
                                    >
                                        {sport.name}
                                    </span>
                                    <button
                                        onClick={() => toggleSportDropdown(sport.id)}
                                        aria-label="Toggle Dropdown"
                                    >
                                        {expandedSportId === sport.id ? <FiChevronUp /> : <FiChevronDown />}
                                    </button>
                                </div>
                                {expandedSportId === sport.id && categories.length > 0 && (
                                    <ul className="pl-4 mt-2 bg-gray-800 rounded shadow-lg">
                                        {categories.map((category) => (
                                            <li
                                                key={category.id}
                                                onClick={() => handleCategoryClick(category.id, sport.id)}
                                                className="text-lg text-gray-300 hover:text-orange-500 cursor-pointer p-2 rounded"
                                            >
                                                {category.name}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default Header;
