// import React, { useEffect } from 'react';
// import { Link, useLocation } from 'react-router-dom';

// export interface NewsArticle {
//   id: number;
//   title: string;
//   content: string;
//   image_path?: string;
//   sport_name?: string;
//   sport_id: number;
// }

// interface NewsSectionProps {
//   title: string;
//   news: NewsArticle[];
//   sport_id: number;
//   color: string;
// }

// const truncateText = (text: string, maxLength: number) => {
//   if (text.length > maxLength) {
//     return text.slice(0, maxLength) + '...';
//   }
//   return text;
// };

// const getImageSource = (imagePath?: string) => {
//   if (!imagePath) {
//     // return `http://localhost:8000/uploads/images/placeholder.jpg`; 
//     return `https://manage.sportski-info.mk/uploads/images/placeholder.jpg`;
//   }

//   const isUrl = (path: string) => /^(http|https):\/\//i.test(path);
//   return isUrl(imagePath)
//     ? imagePath
//     // : `http://localhost:8000/uploads/images/${imagePath}`;
//     : `https://manage.sportski-info.mk/uploads/images/${imagePath}`;
// };

// const NewsSection: React.FC<NewsSectionProps> = ({ title, news, sport_id, color }) => {
//   const filteredNews = news.filter((newsItem) => newsItem.sport_id === sport_id);
//   const location = useLocation();

//   useEffect(() => {
//     if (window.gtag) {
//       window.gtag('config', 'G-LSXWG30DH9', {
//         page_path: location.pathname,
//       });
//     }
//   }, [location]);

//   return (
//     <section className={`${title.toLowerCase()}-section`} id={title.toLowerCase()}>
//       <div className={`title text-2xl ${color} text-white px-3 py-3 uppercase lg:w-1/2 rounded-tr-full`}>
//         {title}
//       </div>
//       <div className='py-1.5 bg-gray-600 w-full'></div>
//       <div className="bg-gray-100 p-4 flex flex-col lg:flex-row gap-8">
//         <div className='w-full lg:w-1/2'>
//           {filteredNews.slice(0, 1).map(newsItem => (
//             <div key={newsItem.id} className="news-item mb-5">
//               <div className="news-image mb-4">
//                 <img
//                   src={getImageSource(newsItem.image_path)}
//                   alt={newsItem.title}
//                   width={100}
//                   height={100}
//                   className="object-cover h-80 w-full rounded-lg"
//                 />
//               </div>
//               <Link to={`/news/${newsItem.id}`}>
//                 <h3 className='font-bold cursor-pointer hover:text-red-500 transition-all duration-300 text-md lg:text-xl mb-3'>
//                   {newsItem.title}
//                 </h3>
//               </Link>
//               <p>
//                 {/* {truncateText(newsItem.content, 80)} */}
//               </p>
//             </div>
//           ))}
//         </div>
//         <div className='w-full lg:w-1/2'>
//           {filteredNews.slice(1).map(newsItem => (
//             <div key={newsItem.id} className="news-item mb-5 flex">
//               <div className="news-image w-24 h-24 flex-shrink-0">
//                 <img
//                   src={getImageSource(newsItem.image_path)}
//                   alt={newsItem.title}
//                   width={100}
//                   height={100}
//                   className="object-cover h-full w-full rounded-lg"
//                 />
//               </div>
//               <div className="news-body flex-grow ms-3">
//                 <Link to={`/news/${newsItem.id}`}>
//                   <h3 className='font-bold cursor-pointer text-md lg:text-lg hover:text-red-500 transition-all duration-300 overflow-hidden whitespace-normal'>
//                     {truncateText(newsItem.title, 55)}
//                   </h3>
//                 </Link>
//                 <div
//                   className="text-sm leading-relaxed"
//                   dangerouslySetInnerHTML={{ __html: truncateText(newsItem.content, 80) }}
//                 />
//               </div>
//             </div>
//           ))}
//         </div>

//       </div>
//     </section>
//   );
// };

// export default NewsSection;
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

export interface NewsArticle {
  id: number;
  title: string;
  content: string;
  image_path?: string;
  sport_name?: string;
  sport_id: number;
  created_at: string;
}

interface NewsSectionProps {
  title: string;
  news: NewsArticle[];
  sport_id: number;
  color: string;
}

const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};

const getImageSource = (imagePath?: string) => {
  if (!imagePath) {
    return `https://manage.sportski-info.mk/uploads/images/placeholder.jpg`;
  }

  const isUrl = (path: string) => /^(http|https):\/\//i.test(path);
  return isUrl(imagePath)
    ? imagePath
    : `https://manage.sportski-info.mk/uploads/images/${imagePath}`;
};

// const NewsSection: React.FC<NewsSectionProps> = ({ title, news, sport_id, color }) => {
//   const filteredNews = news.filter((newsItem) => newsItem.sport_id === sport_id).slice(0, 5); // Limit to 5 items
//   const location = useLocation();

//   useEffect(() => {
//     if (window.gtag) {
//       window.gtag('config', 'GTM-NGBQ98PB', {
//         page_path: location.pathname,
//       });
//     }
//   }, [location]);

//   const formatDate = (dateString: string) => {
//     const date = new Date(dateString);
//     const day = String(date.getDate()).padStart(2, '0');
//     const monthNames = [
//       'Јануари', 'Февруари', 'Март', 'Април', 'Мај', 'Јуни',
//       'Јули', 'Август', 'Септември', 'Октомври', 'Ноември', 'Декември'
//     ];
//     const month = monthNames[date.getMonth()];
//     const year = date.getFullYear();
//     return `${month} ${day}, ${year}`;
//   };

//   return (
//     <section className={`${title.toLowerCase()}-section`} id={title.toLowerCase()}>
//       <div className={`title text-2xl ${color} text-white px-3 py-3 uppercase lg:w-1/2 rounded-tr-full`}>
//         {title}
//       </div>
//       <div className="py-1.5 bg-gray-600 w-full"></div>
//       <div className="bg-gray-100 p-4 flex flex-col lg:flex-row gap-8">
//         <div className="w-full lg:w-1/2">
//           {filteredNews.slice(0, 1).map((newsItem) => (
//             <div key={newsItem.id} className="news-item mb-5">
//               <div className="news-image mb-4">
//                 <img
//                   src={getImageSource(newsItem.image_path)}
//                   alt={newsItem.title}
//                   width={100}
//                   height={100}
//                   className="object-cover h-80 w-full rounded-lg"
//                 />
//               </div>
//               <Link to={`/news/${newsItem.id}`}>
//                 <h3 className="font-bold cursor-pointer hover:text-red-500 transition-all duration-300 text-md text-lg mb-3">
//                   {newsItem.title}
//                 </h3>
//               </Link>
//               <p dangerouslySetInnerHTML={{ __html: truncateText(newsItem.content, 80) }}></p>
//             </div>
//           ))}
//         </div>

//         {/* <div className="w-full lg:w-1/2">
//           {filteredNews.slice(1).map((newsItem) => (
//             <div key={newsItem.id} className="news-item mb-5 flex">
//               <div className="news-image w-24 h-24 flex-shrink-0">
//                 <img
//                   src={getImageSource(newsItem.image_path)}
//                   alt={newsItem.title}
//                   width={100}
//                   height={100}
//                   className="object-cover h-full w-full rounded-lg"
//                 />
//               </div>
//               <div className="news-body flex-grow ms-3">
//                 <Link to={`/news/${newsItem.id}`}>
//                   <h3 className="font-bold cursor-pointer text-md text-lg hover:text-red-500 transition-all duration-300 overflow-hidden whitespace-normal">
//                     {truncateText(newsItem.title, 55)}
//                   </h3>
//                 </Link>
//                 <div
//                   className="text-sm leading-relaxed"
//                   dangerouslySetInnerHTML={{ __html: truncateText(newsItem.content, 80) }}
//                 />
//               </div>
//             </div>
//           ))}
//         </div> */}
//         <div className="w-full lg:w-1/2">
//           {filteredNews.slice(1).map((newsItem) => (
//             <div
//               key={newsItem.id}
//               className="news-item mb-6 flex  border rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 "
//             >
//               <div className="news-image w-28 h-28 flex-shrink-0">
//                 <img
//                   src={getImageSource(newsItem.image_path)}
//                   alt={newsItem.title}
//                   className="object-cover h-full w-full rounded-md"
//                 />
//               </div>

//               <div className="news-body flex-grow ms-4">
//                 {/* Title */}
//                 <Link to={`/news/${newsItem.id}`}>
//                   <h3 className="font-semibold text-base md:text-lg text-gray-800 hover:text-red-500 transition-all duration-300 line-clamp-2">
//                     {truncateText(newsItem.title, 55)}
//                   </h3>
//                 </Link>
//                 <p className='text-gray-400'>{formatDate(newsItem.created_at)}</p>

//                 <div
//                   className="text-sm text-gray-600 mt-2 line-clamp-3 leading-relaxed"
//                   dangerouslySetInnerHTML={{ __html: truncateText(newsItem.content, 80) }}
//                 />
//               </div>
//             </div>
//           ))}
//         </div>

//       </div>
//     </section>
//   );
// };

// export default NewsSection;
const NewsSection: React.FC<NewsSectionProps> = ({ title, news, sport_id, color }) => {
  const sortedNews = news
    .filter((newsItem) => newsItem.sport_id === sport_id)
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    .slice(0, 5);

  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'GTM-NGBQ98PB', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const monthNames = [
      'Јануари', 'Февруари', 'Март', 'Април', 'Мај', 'Јуни',
      'Јули', 'Август', 'Септември', 'Октомври', 'Ноември', 'Декември'
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  return (
    <section className={`${title.toLowerCase()}-section`} id={title.toLowerCase()}>
      <div className={`title text-2xl ${color} text-white px-3 py-3 uppercase lg:w-1/2 rounded-tr-full`}>
        {title}
      </div>
      <div className="py-1.5 bg-gray-600 w-full"></div>
      <div className="bg-gray-100 p-4 flex flex-col lg:flex-row gap-8">
        <div className="w-full lg:w-1/2">
          {sortedNews.slice(0, 1).map((newsItem) => (
            <div key={newsItem.id} className="news-item mb-5">
              <div className="news-image mb-4">
                <img
                  src={getImageSource(newsItem.image_path)}
                  alt={newsItem.title}
                  width={100}
                  height={100}
                  className="object-cover h-80 w-full rounded-lg"
                />
              </div>
              <Link to={`/news/${newsItem.id}`}>
                <h3 className="font-bold cursor-pointer hover:text-red-500 transition-all duration-300 text-md text-lg mb-3">
                  {newsItem.title}
                </h3>
              </Link>
              <p
                className="text-sm text-gray-600 mt-2 leading-relaxed"
                dangerouslySetInnerHTML={{ __html: truncateText(newsItem.content, 432) }}></p>
            </div>
          ))}
        </div>

        <div className="w-full lg:w-1/2">
          {sortedNews.slice(1).map((newsItem) => (
            <div
              key={newsItem.id}
              className="news-item mb-6 flex border rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 "
            >
              <div className="news-image w-28 h-28 flex-shrink-0">
                <img
                  src={getImageSource(newsItem.image_path)}
                  alt={newsItem.title}
                  className="object-cover h-full w-full rounded-md"
                />
              </div>

              <div className="news-body flex-grow ms-4">
                {/* Title */}
                <Link to={`/news/${newsItem.id}`}>
                  <h3 className="font-semibold text-base md:text-lg text-gray-800 hover:text-red-500 transition-all duration-300 line-clamp-2">
                    {truncateText(newsItem.title, 55)}
                  </h3>
                </Link>
                <p className="text-gray-400">{formatDate(newsItem.created_at)}</p>

                <div
                  className="text-sm text-gray-600 mt-2 line-clamp-3 leading-relaxed"
                  dangerouslySetInnerHTML={{ __html: truncateText(newsItem.content, 80) }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewsSection;