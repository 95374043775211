import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Footer() {
    const year = new Date().getFullYear();

    return (
        <footer className="bg-gradient-to-r from-black via-gray-900 to-black w-full text-white py-10">
            <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between px-8 lg:px-20 gap-8">
                
                <div className="text-center lg:text-left">
                    <img src="/images/logo.png" alt="Logo" className="h-10 mb-4 mx-auto lg:mx-0" />
                    <p className="text-sm text-gray-400">&copy; {year} Спортски Инфо. Сите права задржани.</p>
                </div>
                
                <div className="text-center">
                    <h4 className="text-lg font-semibold mb-3">Контакт</h4>
                    <p className="text-gray-400">Е-маил: info@sportsinfo.com</p>
                </div>
                
                <div className="flex gap-6">
                    <Link to="https://www.facebook.com/Sportskiinfo.makedonija7" target='_blank' rel="noreferrer" className="text-gray-400 hover:text-blue-500 transition-colors duration-200">
                        <FaFacebookF size={20} />
                    </Link>
                    <Link to="https://www.tiktok.com/@sportski..info"target='_blank' rel="noreferrer" className="text-gray-400 hover:text-blue-400 transition-colors duration-200">
                        <FaTiktok size={20} />
                    </Link>
                    <Link to="https://www.instagram.com/sportski.info/?hl=en"target='_blank' rel="noreferrer" className="text-gray-400 hover:text-pink-500 transition-colors duration-200">
                        <FaInstagram size={20} />
                    </Link>
                </div>
            </div>

            <div className="bg-custom-blue w-full h-1 mt-8"></div>
        </footer>
    );
}

export default Footer;
