import { useEffect } from 'react';
import { FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

const SocialMedia: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        if (window.gtag) {
            window.gtag('config', 'GTM-NGBQ98PB', {
                page_path: location.pathname,
            });
        }
    }, [location]);
    return (
        <div className="w-full ">
            <h2 className='text-gray-800 font-semibold text-xl mb-5'>Заследи Не</h2>
            <div className="flex w-full h-full justify-center ">
                <Link to={"https://www.instagram.com/sportski.info/?hl=en"} target='_blank' rel='norefferer'>
                    <div className="text-white text-4xl py-3 px-8 bg-red-500 hover:bg-red-600">
                        <FaInstagram />
                    </div>
                </Link>
                <Link to={"https://www.facebook.com/Sportskiinfo.makedonija7"} target='_blank' rel='norefferer'>
                    <div className="text-white text-4xl py-3 px-8 bg-blue-600 hover:bg-blue-700">
                        <FaFacebook />
                    </div>
                </Link>
                <Link to={"https://www.tiktok.com/@sportski..info"} target='_blank' rel='norefferer'>
                    <div className="text-white text-4xl py-3 px-8 bg-gray-800 hover:bg-gray-700">
                        <FaTiktok />
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default SocialMedia;
