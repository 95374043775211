import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { NewsArticle } from './Home';
import RightContainer from './RightContainer';
import Comments from './Comments';
import Recommended from './Recommended';
import { FaCalendarAlt, FaFacebook, FaUserAlt } from 'react-icons/fa';
import Banner from './Banner';
import NewsDetailsAds from './NewsDetailsAds';

const NewsDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [newsItem, setNewsItem] = useState<NewsArticle | null>(null);
    const [categoryName, setCategoryName] = useState<string | null>(null);
    const [sportName, setSportName] = useState<string | null>(null);
    const [userName, setUserName] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const location = useLocation();

    useEffect(() => {
        if (window.gtag) {
            window.gtag('config', 'GTM-NGBQ98PB', {
                page_path: location.pathname,
            });
        }
    }, [location]);

    useEffect(() => {
        const fetchNewsItem = async () => {
            try {
                // const res = await fetch(`http://localhost:8000/api/news/${id}`);
                const res = await fetch(`https://manage.sportski-info.mk/api/news/${id}`);
                const data = await res.json();
                setNewsItem(data.data);
            } catch (error) {
                setError('Грешка');
            }
        };

        if (id) {
            fetchNewsItem();
        }
    }, [id]);

    useEffect(() => {
        const fetchCategoryName = async (category_id: number) => {
            try {
                // const res = await fetch(`http://localhost:8000/api/categories/${category_id}`);
                const res = await fetch(`https://manage.sportski-info.mk/api/categories/${category_id}`);
                const data = await res.json();
                setCategoryName(data.category_name);
            } catch (error) {
                setError('Грешка');
            } finally {
                setLoading(false);
            }
        };

        if (newsItem?.category_id) {
            fetchCategoryName(newsItem.category_id);
        }
    }, [newsItem?.category_id]);

    useEffect(() => {
        const fetchSportName = async (sport_id: number) => {
            try {
                // const res = await fetch(`http://localhost:8000/api/sports/name/${sport_id}`);
                const res = await fetch(`https://manage.sportski-info.mk/api/sports/name/${sport_id}`);
                const data = await res.json();
                if (res.ok) {
                    setSportName(data.sport_name);
                }
            } catch (error) {
                setError('Грешка');
            } finally {
                setLoading(false);
            }
        };

        if (newsItem?.sport_id) {
            fetchSportName(newsItem.sport_id);
        }
    }, [newsItem?.sport_id]);

    useEffect(() => {
        const fetchUserName = async (user_id: number) => {
            try {
                // const res = await fetch(`http://localhost:8000/api/users/${user_id}`);
                const res = await fetch(`https://manage.sportski-info.mk/api/users/${user_id}`);
                const data = await res.json();
                if (res.ok) {
                    setUserName(data.user_name);
                }
            } catch (error) {
                console.error('Грешка:', error);
            } finally {
                setLoading(false);
            }
        };

        if (newsItem?.user_id) {
            fetchUserName(newsItem.user_id);
        }
    }, [newsItem?.user_id]);

    // const formatDate = (dateString: string) => {
    //     const date = new Date(dateString);
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const year = date.getFullYear();
    //     return `${day}/${month}/${year}`;
    // };
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const monthNames = [
            'Јануари', 'Февруари', 'Март', 'Април', 'Мај', 'Јуни',
            'Јули', 'Август', 'Септември', 'Октомври', 'Ноември', 'Декември'
        ];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${month} ${day}, ${year}`;
    };

    const getImageSource = (imagePath?: string) => {
        if (!imagePath) {
            // return `http://localhost:8000/uploads/images/placeholder.jpg`;
            return `https://manage.sportski-info.mk/uploads/images/placeholder.jpg`;
        }

        const isUrl = (path: string) => /^(http|https):\/\//i.test(path);
        return isUrl(imagePath)
            ? imagePath
            // : `http://localhost:8000/uploads/images/${imagePath}`;
            : `https://manage.sportski-info.mk/uploads/images/${imagePath}`;
    };

    if (error) {
        return <p>{error}</p>;
    }

    if (!newsItem) {
        return <p>Веста не е пронајдена</p>;
    }

    const currentUrl = window.location.href;
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(newsItem.title)}`;

    return (
        <div className="wrapper max-w-screen-xl lg:w-4/5 w-full m-auto min-h-screen lg:px-0 lg:py-5 pt-0 pb-3">
            <div className="lg:hidden flex">
                <Banner />
            </div>
            <div className="flex flex-col lg:flex-row gap-5">
                <div className="w-full lg:w-2/3 bg-gray-100">
                    {/* <div className="relative w-full">
                        {newsItem.image_path && (
                            <img
                                src={getImageSource(newsItem.image_path)}
                                alt={newsItem.title}
                                className="w-full h-96 object-cover"
                            />
                        )}
                    </div> */}
                    <div className="relative w-full">
                        {newsItem.image_path && (
                            <img
                                src={getImageSource(newsItem.image_path)}
                                alt={newsItem.title}
                                className="w-full h-auto object-cover md:h-96"
                            />
                        )}
                    </div>

                    <div className="news-body m-auto pb-4 mt-8 lg:px-8 px-3">
                        <div className="flex items-center gap-2">
                            <span className="inline-flex items-center bg-red-600 text-white text-sm font-semibold px-4 py-1 capitalize">
                                {sportName || 'Loading...'}
                            </span>
                            <span className="inline-flex items-center bg-gray-600 text-white text-sm font-semibold px-4 py-1 capitalize">
                                {categoryName || 'Loading...'}
                            </span>
                        </div>
                        <h1 className="font-bold my-5 lg:text-2xl text-lg">{newsItem.title}</h1>
                        <span className="text-gray-400 flex items-center gap-2">
                            <FaCalendarAlt /> Поставено на {formatDate(newsItem.created_at)}
                        </span>
                        <span className="text-gray-400 flex items-center gap-2 mt-3">
                            <FaUserAlt /> {userName || 'Loading...'}
                        </span>
                        <div
                            className="mt-8 text-sm leading-relaxed"
                            dangerouslySetInnerHTML={{ __html: newsItem.content }}
                        />
                        <NewsDetailsAds />
                        <div className="share-buttons mt-8">
                            <p className="text-gray-600 mb-2">Сподели пост:</p>
                            <div className="flex space-x-4">
                                <a
                                    href={facebookShareUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 duration-300 transition-all flex items-center space-x-2"
                                >
                                    <FaFacebook />
                                    <span>Сподели</span>
                                </a>
                                <a
                                    href={twitterShareUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800 duration-300 transition-all flex items-center space-x-2"
                                >
                                    <img src="/images/xlogo.png" width={20} alt="" />
                                    <span>Сподели</span>
                                </a>
                            </div>
                        </div>
                        <Comments newsId={Number(id)} />
                        {newsItem.sport_id && <Recommended sport_id={newsItem.sport_id} exclude_id={newsItem.id} />}
                    </div>
                </div>
                <RightContainer />
            </div>
        </div>
    );
};

export default NewsDetail;
