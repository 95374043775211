import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

export interface Ad {
    id: number;
    ad_content: string;
    location: string;
    link: string;
}

const Banner: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [ads, setAds] = useState<Ad[]>([]);
    const [error, setError] = useState<string | null>(null);
    const location = useLocation();

    useEffect(() => {
        if (window.gtag) {
            window.gtag('config', 'GTM-NGBQ98PB', {
                page_path: location.pathname,
            });
        }
    }, [location]);

    useEffect(() => {
        const fetchAds = async () => {
            try {
                // const response = await axios.get('http://localhost:8000/api/ads');
                const response = await axios.get('https://manage.sportski-info.mk/api/ads');

                if (Array.isArray(response.data)) {
                    const bannerAds = response.data.filter(ad => ad.location === 'header');
                    setAds(bannerAds);
                } else {
                    console.error("Грешка: ", response.data);
                }
            } catch (error) {
                console.error('Грешка::', error);
                setError('Настана грешка');
            }
        };

        fetchAds();
    }, []);

    useEffect(() => {
        if (ads.length > 1) {
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % ads.length);
            }, 3000);

            return () => clearInterval(interval);
        }
    }, [ads.length]);

    if (error) {
        return <p>{error}</p>;
    }

    const ad = ads.length > 0 ? ads[currentIndex] : null;

    return (
        <div className="mb-5">
            <div className="carousel relative overflow-hidden">
                {ad ? (
                    <div>
                        <a href={ad.link} target="_blank" rel="noopener noreferrer">
                            <img
                                // src={`http://localhost:8000/uploads/ads/${ad.ad_content}`}
                                src={`https://manage.sportski-info.mk/uploads/ads/${ad.ad_content}`}
                                alt={`Ad ${ad.id}`}
                                className="object-cover lg:h-96 h-auto d-block lg:w-[1250px] w-screen"
                            />
                        </a>
                    </div>
                ) : (
                    <p>Нема реклами за прикажување</p>
                )}
            </div>
        </div>
    );
};

export default Banner;
