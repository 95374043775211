import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaRegClock } from 'react-icons/fa';
import { NewsArticle } from './Home';
import SocialMedia from './SocialMedia';
import { Ad } from './Banner';
import axios from 'axios';
import Loading from './Loading';

const RightContainer: React.FC = () => {
    const [newsData, setNewsData] = useState<NewsArticle[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [right1, setAds] = useState<Ad[]>([]);
    const [right2, setAds2] = useState<Ad[]>([]);
    const [right1Index, setRight1Index] = useState(0);
    const [right2Index, setRight2Index] = useState(0);
    const location = useLocation();

    useEffect(() => {
        if (window.gtag) {
            window.gtag('config', 'GTM-NGBQ98PB', {
                page_path: location.pathname,
            });
        }
    }, [location]);

    // useEffect(() => {
    //     const fetchNewsData = async () => {
    //         try {
    //             const res = await fetch('https://manage.sportski-info.mk/api/news');
    //             const data = await res.json();

    //             const twentyFourHoursAgo = Date.now() - 24 * 60 * 60 * 1000;
    //             const filteredNews = data.data.filter((item: NewsArticle) => {
    //                 return new Date(item.created_at).getTime() >= twentyFourHoursAgo;
    //             });

    //             setNewsData(filteredNews);
    //         } catch (error) {
    //             console.error('Грешка:', error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchNewsData();
    // }, []);
    useEffect(() => {
        const fetchNewsData = async () => {
            try {
                const res = await fetch('https://manage.sportski-info.mk/api/news');
                const data = await res.json();

                const twentyFourHoursAgo = Date.now() - 24 * 60 * 60 * 1000;
                const filteredNews = data.data.filter((item: NewsArticle) => {
                    return new Date(item.created_at).getTime() >= twentyFourHoursAgo;
                });

                const sortedNews = filteredNews.sort((a: NewsArticle, b: NewsArticle) => {
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                });

                setNewsData(sortedNews);
            } catch (error) {
                console.error('Грешка:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNewsData();
    }, []);


    useEffect(() => {
        const fetchAds = async () => {
            try {
                const res = await axios.get<Ad[]>('https://manage.sportski-info.mk/api/ads');
                const right1Ads = res.data.filter(ad => ad.location === 'right1');
                const right2Ads = res.data.filter(ad => ad.location === 'right2');
                setAds(right1Ads);
                setAds2(right2Ads);
            } catch (error) {
                setError('Грешка');
            }
        };

        fetchAds();
    }, []);

    useEffect(() => {
        if (right1.length > 1) {
            const interval = setInterval(() => {
                setRight1Index((prevIndex) => (prevIndex + 1) % right1.length);
            }, 2000);
            return () => clearInterval(interval);
        }
    }, [right1]);

    useEffect(() => {
        if (right2.length > 1) {
            const interval = setInterval(() => {
                setRight2Index((prevIndex) => (prevIndex + 1) % right2.length);
            }, 3000);
            return () => clearInterval(interval);
        }
    }, [right2]);

    useEffect(() => {
        const fetchSportNames = async () => {
            const updatedNewsData = await Promise.all(
                newsData.map(async (newsItem) => {
                    // Check if the sport_name already exists
                    if (!newsItem.sport_name && newsItem.sport_id) {
                        try {
                            const res = await fetch(`https://manage.sportski-info.mk/api/sports/name/${newsItem.sport_id}`);
                            const data = await res.json();
                            if (res.ok) {
                                return { ...newsItem, sport_name: data.sport_name };
                            }
                        } catch (error) {
                            console.error('Грешка при прикажување на најновите вести:', error);
                        }
                    }
                    return newsItem;
                })
            );
            setNewsData(updatedNewsData);
        };

        if (newsData.length > 0) {
            fetchSportNames();
        }
    }, [newsData]);  // This ensures that it only triggers when newsData is updated

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const monthNames = [
            'Јануари', 'Февруари', 'Март', 'Април', 'Мај', 'Јуни',
            'Јули', 'Август', 'Септември', 'Октомври', 'Ноември', 'Декември'
        ];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${month} ${day}, ${year}`;
    };

    const getImageSource = (imagePath?: string) => {
        if (!imagePath) {
            return `https://manage.sportski-info.mk/uploads/ads/placeholder.jpg`;
        }

        const isUrl = (path: string) => /^(http|https):\/\//i.test(path);
        return isUrl(imagePath)
            ? imagePath
            : `https://manage.sportski-info.mk/uploads/ads/${imagePath}`;
    };

    const getImageSource2 = (imagePath?: string) => {
        if (!imagePath) {
            return `https://manage.sportski-info.mk/uploads/images/placeholder.jpg`;
        }

        const isUrl = (path: string) => /^(http|https):\/\//i.test(path);
        return isUrl(imagePath)
            ? imagePath
            : `https://manage.sportski-info.mk/uploads/images/${imagePath}`;
    };

    const truncateText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="right-container w-full lg:w-1/4 flex-col hidden lg:flex">
            <SocialMedia />

            <div className="ad-container w-full h-[400px] my-5 flex justify-center items-center shadow-lg overflow-hidden">
                {right1.length > 0 && (
                    <div>
                        <a href={right1[right1Index].link} target="_blank" rel="noopener noreferrer">
                            <img
                                src={getImageSource(right1[right1Index].ad_content)}
                                alt={`Ad ${right1[right1Index].id}`}
                                className="w-full h-full object-cover transition-all hover:scale-105"
                            />
                        </a>
                    </div>
                )}
            </div>

            <div className="p-2 bg-gray-100 rounded-lg shadow-lg mb-6">
                <div className="uppercase font-bold text-lg">
                    <h2 className="flex items-center gap-2 text-gray-800">
                        <FaRegClock /> Најнови вести
                    </h2>
                </div>
                <div className="bg-red-600 py-1 rounded-t-lg"></div>
            </div>

            <div className="news-container bg-white rounded-lg h-screen overflow-y-auto shadow-lg p-1 mb-6">
                {newsData.map((item) => (
                    <div key={item.id} className="news-item flex items-center mb-6 border-b last:border-b-0">
                        <img
                            src={getImageSource2(item.image_path)}
                            alt={item.id.toString()}
                            className="w-16 h-16 object-cover rounded-lg mr-4"
                        />
                        <div className="flex-grow">
                            <span className="text-base text-gray-600">
                                <span className="text-blue-500 font-semibold">{item.sport_name} </span>
                                / <span className="text-gray-400">{formatDate(item.created_at)}</span>
                            </span>
                            <Link to={`/news/${item.id}`}>
                                <h3 className="text-sm text-black hover:text-red-500 transition-all duration-300 mt-1">
                                    {truncateText(item.title, 50)}
                                </h3>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>

            <div className="ad-container w-full h-[450px] my-5 flex justify-center items-center shadow-lg overflow-hidden">
                {right2.length > 0 && (
                    <div>
                        <a href={right2[right2Index].link} target="_blank" rel="noopener noreferrer">
                            <img
                                src={getImageSource(right2[right2Index].ad_content)}
                                alt={`Ad ${right2[right2Index].id}`}
                                className="w-full h-full object-cover transition-all hover:scale-105"
                            />
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RightContainer;
