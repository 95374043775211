// import React, { useEffect, useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import { FaRegClock } from 'react-icons/fa';
// import { NewsArticle } from './Home';
// import Loading from './Loading';

// const NewestNews: React.FC = () => {
//     const [newsData, setNewsData] = useState<NewsArticle[]>([]);
//     const [loading, setLoading] = useState<boolean>(true);
//     const [error, setError] = useState<string | null>(null);
//     const location = useLocation();

//     useEffect(() => {
//         if (window.gtag) {
//             window.gtag('config', 'G-LSXWG30DH9', {
//                 page_path: location.pathname,
//             });
//         }
//     }, [location]);

//     useEffect(() => {
//         const fetchNewsData = async () => {
//             try {
//                 // const res = await fetch('http://localhost:8000/api/news');
//                 const res = await fetch('https://manage.sportski-info.mk/api/news');
//                 const data = await res.json();

//                 const twentyFourHoursAgo = Date.now() - 24 * 60 * 60 * 1000;
//                 const filteredNews = data.data.filter((item: NewsArticle) => {
//                     return new Date(item.created_at).getTime() >= twentyFourHoursAgo;
//                 });

//                 setNewsData(filteredNews);
//             } catch (error) {
//                 console.error('Error:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchNewsData();
//     }, []);

//     const truncateText = (text: string, maxLength: number) => {
//         if (text.length > maxLength) {
//             return text.slice(0, maxLength) + '...';
//         }
//         return text;
//     };

//     const getImageSource = (imagePath?: string) => {
//         if (!imagePath) {
//             // return `http://localhost:8000/uploads/images/placeholder.jpg`;
//             return `https://manage.sportski-info.mk/uploads/images/placeholder.jpg`;
//         }

//         const isUrl = (path: string) => /^(http|https):\/\//i.test(path);
//         return isUrl(imagePath)
//             ? imagePath
//             // : `http://localhost:8000/uploads/images/${imagePath}`;
//             : `https://manage.sportski-info.mk/uploads/images/${imagePath}`;
//     };

//     if (loading) {
//         return <Loading />;
//     }

//     if (error) {
//         return <p>{error}</p>;
//     }

//     return (
//         <div className="w-full lg:hidden flex flex-col p-4  ">
//             <div className="mb-4 text-center">
//                 <h2 className="text-2xl font-bold flex items-center justify-center gap-2">
//                     <FaRegClock />
//                     Најнови вести
//                 </h2>
//                 <div className="mt-1 h-0.5 w-16 bg-red-600 mx-auto"></div>
//             </div>

//             <div className="flex space-x-4 overflow-x-auto pb-4">
//                 {newsData.map((item) => (
//                     <div key={item.id} className="relative flex-shrink-0 w-64 h-44 rounded-lg overflow-hidden shadow-md">
//                         <img
//                             src={getImageSource(item.image_path)}
//                             alt={item.title}
//                             className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
//                         />
//                         <div className="absolute bottom-0 left-0 w-full p-3 bg-gradient-to-t from-black to-transparent text-white">
//                             <Link to={`/news/${item.id}`} className="hover:text-red-500 transition-colors duration-200">
//                                 <h3 className="text-sm font-semibold">{truncateText(item.title, 60)}</h3>
//                             </Link>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default NewestNews;
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaRegClock } from 'react-icons/fa';
import { NewsArticle } from './Home';
import Loading from './Loading';

const NewestNews: React.FC = () => {
    const [newsData, setNewsData] = useState<NewsArticle[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const location = useLocation();

    useEffect(() => {
        if (window.gtag) {
            window.gtag('config', 'GTM-NGBQ98PB', {
                page_path: location.pathname,
            });
        }
    }, [location]);

    useEffect(() => {
        const fetchNewsData = async () => {
            try {
                const res = await fetch('https://manage.sportski-info.mk/api/news');
                const data = await res.json();

                const twentyFourHoursAgo = Date.now() - 24 * 60 * 60 * 1000;
                const filteredNews = data.data.filter((item: NewsArticle) => {
                    return new Date(item.created_at).getTime() >= twentyFourHoursAgo;
                });

                const sortedNews = filteredNews.sort((a: NewsArticle, b: NewsArticle) => {
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                });

                setNewsData(sortedNews);

            } catch (error) {
                console.error('Грешка:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNewsData();
    }, []);

    const truncateText = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };

    const getImageSource = (imagePath?: string) => {
        if (!imagePath) {
            return `https://manage.sportski-info.mk/uploads/images/placeholder.jpg`;
        }

        const isUrl = (path: string) => /^(http|https):\/\//i.test(path);
        return isUrl(imagePath)
            ? imagePath
            : `https://manage.sportski-info.mk/uploads/images/${imagePath}`;
    };

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        // <div className="w-full lg:hidden flex flex-col p-4">
        //     <div className="mb-6 text-center">
        //         <h2 className="text-2xl font-extrabold text-gray-800 flex justify-center items-center gap-2">
        //             <FaRegClock size={24} className="text-red-600" />
        //             <span className='text-2xl'>Најнови вести</span>
        //         </h2>
        //         <div className="mt-2 w-24 h-1 mx-auto bg-red-600 rounded-full"></div>
        //     </div>

        //     <div className="flex space-x-4 overflow-x-auto pb-4">
        //         {newsData.map((item) => (
        //             <div key={item.id} className="flex-shrink-0 w-64 h-72 rounded-lg overflow-hidden shadow-md bg-white transform transition-all duration-300 hover:scale-105">
        //                 <div className="relative w-full h-full group">
        //                     <img
        //                         src={getImageSource(item.image_path)}
        //                         alt={item.title}
        //                         className="w-full h-full object-cover rounded-lg transition-all duration-300 group-hover:opacity-70"
        //                     />
        //                     <div className="absolute bottom-0 left-0 w-full p-4 bg-gradient-to-t from-black via-transparent to-transparent">
        //                         <Link to={`/news/${item.id}`} className="block">
        //                             <h3 className="text-lg font-semibold text-white hover:text-red-500 transition-colors duration-300">
        //                                 {truncateText(item.title, 50)}
        //                             </h3>
        //                         </Link>
        //                     </div>
        //                 </div>
        //             </div>
        //         ))}
        //     </div>
        // </div>
        <div className="w-full lg:hidden flex flex-col p-4">
            <div className="mb-6 text-center">
                <h2 className="text-2xl font-extrabold text-gray-800 flex justify-center items-center gap-2">
                    <FaRegClock size={24} className="text-red-600" />
                    <span className='text-2xl'>Најнови вести</span>
                </h2>
                <div className="mt-2 w-24 h-1 mx-auto bg-red-600 rounded-full"></div>
            </div>

            <div className="flex space-x-4 overflow-x-auto pb-4">
                {newsData.map((item) => (
                    <div key={item.id} className="flex-shrink-0 w-64 h-72 rounded-lg overflow-hidden shadow-md bg-white transform transition-all duration-300 hover:scale-105">
                        <div className="relative w-full h-full group">
                            <img
                                src={getImageSource(item.image_path)}
                                alt={item.title}
                                className="w-full h-full object-cover rounded-lg transition-all duration-300 group-hover:opacity-70"
                            />
                            <div className="absolute inset-0 w-full h-full bg-gradient-to-t from-black via-black/60 to-transparent"></div>
                            <div className="absolute bottom-0 left-0 w-full p-4 bg-gradient-to-t from-black via-transparent to-transparent">
                                <Link to={`/news/${item.id}`} className="block">
                                    <h3 className="text-lg font-semibold text-white hover:text-red-500 transition-colors duration-300">
                                        {truncateText(item.title, 50)}
                                    </h3>
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NewestNews;
